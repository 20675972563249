export default [
    // {
    //     visible: () => true,
    //     open:true,
    //     title: "Temporary Phone Line Fault",
    //     content: "<p>We have a temporary fault with our phone lines, and are unable to answer incoming calls.</p>" +
    //         "<p>Until this is resolved, if you need to contact the office, could you please send an Instant Message via the member’s area or send on email to <a href='enquiries@cashbox.org.uk' target='_blank'>enquiries@cashbox.org.uk</a></p>"
    // },
    // {
    //     title: "Bank Holiday Weekend",
    //     image: "spring.png",
    //     content: "<img alt='Spring Bank Holiday' src=\"/alerts/spring.png\" " +
    //         "class=\"p-0 m-0 pb-3 float-none rounded float-sm-right col col-sm-6 pl-sm-3 col-md-4 pb-md-0 \" />" +
    //         "<p>Our offices will be closed on Monday 27th May 2024 for the Bank Holiday.</p>" +
    //         // "<p>We will reopen at 9am on Tuesday 30th May 2023.</p>" +
    //         // "<p>Child Benefit Payments due into accounts on Monday 29th May 2023 will be processed on Friday 26th May 2023, on receipt from HMRC.</p>" +
    //         "<p>Loan applications submitted after Tuesday 21st May 2024 will be assessed on Wednesday 29th May 2024.</p>" +
    //         "<p>We would like to wish all our members a happy Bank Holiday Weekend!</p>",
    //     visible: () => {
    //         const today = new Date();
    //         const springBankHoliday = new Date(today.getFullYear(), 4, 27);
    //         const beforeBankHoliday = new Date(springBankHoliday);
    //         beforeBankHoliday.setDate(beforeBankHoliday.getDate() - 14);
    //         const afterBankHoliday = new Date(springBankHoliday);
    //         afterBankHoliday.setDate(afterBankHoliday.getDate() + 3);
    //         return today > beforeBankHoliday && today < afterBankHoliday;
    //     },
    //     open: true
    // },
    // {
    //     title: "May Bank Holiday",
    //     image: "may.png",
    //     content: "<img alt='easter' src=\"/alerts/may.png\" " +
    //         "class=\"p-0 m-0 pb-3 float-none rounded float-sm-right col col-sm-6 pl-sm-3 col-md-4 pb-md-0 \" />" +
    //         "<p>Our offices will be closed on Monday 1st May 2023.</p>" +
    //         "<p>We will reopen at 9am on Tuesday 2nd May 2023.</p>" +
    //         "<p>Child Benefit Payments due into accounts on Monday 1st May 2023 will be processed on Friday 28th April 2023, on receipt from HMRC.</p>" +
    //         "<p>Loan applications submitted after Tuesday 25th April 2023 will be reviewed on Wednesday 3rd May 2023.</p>" +
    //         "<p>Enjoy the Bank Holiday Weekend!</p>",
    //     visible: () => {
    //         const today = new Date();
    //         const mayDay = new Date(today.getFullYear(), 3, 1);
    //         const beforeMayDay = new Date(mayDay);
    //         beforeMayDay.setDate(beforeMayDay.getDate() - 4);
    //         const afterMayDay = new Date(mayDay);
    //         afterMayDay.setDate(afterMayDay.getDate() + 3);
    //         return today > beforeMayDay && today < afterMayDay;
    //     },
    //     open: true
    // },
    // {
    //     title: "Easter Opening Hours",
    //     image: "easter.png",
    //     content: "<img alt='easter' src=\"/alerts/easter.png\" " +
    //         "class=\"p-0 m-0 pb-3 float-none rounded float-sm-right col col-sm-6 pl-sm-3 col-md-4 pb-md-0 \" />" +
    //         "<p>Our offices will be closed from 4:30pm on Thursday 28th March 2024.</p>" +
    //         "<p>We reopen at 9am on Tuesday 2nd April 2024.</p>" +
    //         "<p>Child Benefit Payments due into accounts on Monday 1st April 2024 will be processed today, Thursday 28th March 2024.</p>" +
    //         "<p>Loan applications submitted after Thursday 28th March 2024 will be reviewed on Wednesday 3rd April 2024.</p>" +
    //         "<p>We would like to wish all our members a Happy Easter!</p>",
    //     visible: () => {
    //         const today = new Date();
    //         const easterMonday = new Date(today.getFullYear(), 3, 1);
    //         const beforeEasterMonday = new Date(easterMonday);
    //         beforeEasterMonday.setDate(beforeEasterMonday.getDate() - 4);
    //         const easterMondayPlusOne = new Date(easterMonday);
    //         easterMondayPlusOne.setDate(easterMondayPlusOne.getDate() + 1);
    //         return today > beforeEasterMonday && today < easterMondayPlusOne;
    //     },
    //     open: true
    // },
    {
        title: "August Bank Holiday",
        image: "easter.png",
        content: "<img alt='august bank holiday' src=\"/alerts/august.png\" " +
            "class=\"p-0 m-0 pb-3 float-none rounded float-sm-right col col-sm-6 pl-sm-3 col-md-4 pb-md-0 \" />" +
            "<p>Our offices will be closed on Monday 26th August 2024 for the Bank Holiday.</p>" +
            "<p>Loan applications submitted after Tuesday 20th August 2024 will be assessed on Wednesday 27th August 2024.</p>" +
            "<p>We would like to wish all our members a Happy Bank Holiday Weekend!</p>",
        visible: () => {
            const today = new Date();
            const augustBankHolidayMondayPlusOne = new Date(today.getFullYear(), 7, 27);
            const beforeAugustBankHoliday = new Date(augustBankHolidayMondayPlusOne);
            beforeAugustBankHoliday.setDate(beforeAugustBankHoliday.getDate() - 5);
            return today > beforeAugustBankHoliday && today < augustBankHolidayMondayPlusOne;
        },
        open: true
    },
    // {
    //     title: "Child Benefit Payments Monday 2nd October 2023",
    //     content: "<p>" +
    //         "There may be a delay with payments due to be paid out to member’s bank accounts today." +
    //         "<p>" +
    //         "We will transfer the funds over as soon as possible." +
    //         "</p>",
    //     visible: () => {
    //         const today = new Date();
    //         const octoberFifth = new Date(today.getFullYear(), 9, 5);
    //         return today < octoberFifth;
    //     },
    //     open: true
    // },
    // {
    //     title: "AGM 2023 Attendance...",
    //     content: "<p>" +
    //         "Everyone at the Credit Union would like to thank all our members who attended the AGM earlier this month!</p><p>Due to the good attendance, we have donated £50 to ‘The bread and butter thing’ as promised in our AGM invitation." +
    //         "</p>",
    //     open: true
    // },
    // {
    //     title: "Christmas Saver 2023",
    //     open: true,
    //     content: "<p>" +
    //         "It's that time of year again!" +
    //         "</p>" +
    //         "<p>" +
    //         "Cash Box Christmas Savings are due to be paid to nominated bank accounts by close of business on Tuesday 7th November 2023." +
    //         "</p>" +
    //         "<p>" +
    //         "If you don’t already save with Cash Box why not join the 535 members who have put away a little something towards Christmas this year." +
    //         "</p>" +
    //         "<p>" +
    //         "<a href='/christmas-savings'>Click here</a> to find out more." +
    //         "</p>"
    // },
    // {
    //     title: "Loan Applications...",
    //     content: "<p>" +
    //         "When you submit a loan application via the members area, you will " +
    //         "receive a notification from us confirming it has been received. If any " +
    //         "further information is required from you we will let you know." +
    //         "</p>" +
    //         "<p>" +
    //         "The average time for a loan decision is 7-10 working days from the " +
    //         "date of application. Please do not contact us for a decision prior to " +
    //         "this unless your circumstances have changed since you submitted your " +
    //         "application." +
    //         "</p>"
    // },
    // {
    //     title: "Helping our members...",
    //     content: "<p>" +
    //         "During the pandemic we improved our online access for members to make " +
    //         "it easier for you to access your savings, apply for products and fully " +
    //         "utilise your membership with Cash Box." +
    //         "</p>" +
    //         "<p>" +
    //         "Visit your " +
    //         "<a href=\"https://cashbox.cuaccount.com/login/\" target=\"_blank\"\n" +
    //         ">new members area</a\n" +
    //         "> " +
    //         "where you can see your account balance, request a share withdrawal and " +
    //         "apply for a loan, Christmas Saver or a Junior Saver for your children." +
    //         "</p>" +
    //         "<p>" +
    //         "Our collection points at Ashton, Denton and Hyde remain closed but you " +
    //         "can continue to make regular payments to your accounts by standing " +
    //         "order or one-off payments via bill payment. Our staff will be happy to " +
    //         "assist you with this." +
    //         "</p>" +
    //         "<p>" +
    //         "Our staff continue to work remotely Monday to Thursday, and are available " +
    //         "by email and phone for member enquiries." +
    //         "</p>" +
    //         "<p>" +
    //         "On Fridays, we will be working from our Denton office where you can " +
    //         "pop in to see us if you need to between 10am and 1pm." +
    //         "</p>" +
    //         "<p>If you need to contact us, please get in touch:</p>" +
    //         "<p>" +
    //         "Email: <a href=\"mailto:enquiries@cashbox.org.uk\">enquiries@cashbox.org.uk</a>" +
    //         "</p>" +
    //         "<p>" +
    //         "Instant Message (IM) by logging into your " +
    //         "<a href=\"https://cashbox.cuaccount.com/login/\" target=\"_blank\">Members Area</a>." +
    //         "</p>" +
    //         "<p>" +
    //         "Telephone: <a href=\"tel:01613423266\">0161 342 3266</a>" +
    //         "<br />" +
    //         "Available from 11am to 1pm Monday, Tuesday, Thursday and Friday" +
    //         "</p>"
    // },
    {
        title: "Denton Office Closed - Friday 13th December 2024",
        content: "<p>Our Denton Office will be closed on Friday 13th December for staff training.</p>" +
            "<p>You can still contact us by phone or email, and you will still be able to log onto the members area and carry out transactions online.</p>" +
            "<p>We apologise for any inconvenience this may cause and thank you for your understanding.</p>",
        visible: () => {
            const today = new Date();
            const dentonClosed = new Date(today.getFullYear(), 11, 13);
            return today <= dentonClosed;
        },
        open: true
    },
    {
        title: "Christmas Opening Times",
        image: "xmas.png",
        content: "<img alt='Christmas Opening Times' src=\"/alerts/xmas.png\" " +
                 "class=\"p-0 m-0 pb-3 float-none rounded float-sm-right col col-sm-6 pl-sm-3 col-md-4 pb-md-0 \" />" +
            "<p>Our offices will be closed from 1.00pm on Tuesday 24th December 2024 and reopen at 9am on Thursday 2nd January 2025.</p>" +
            "<p>The latest date for loan applications requiring a decision before Christmas is Monday, 16th December 2024 and you will need to ensure that any evidence we require to support your loan application is provided before 5pm.</p>" +
            "<p>We would like to wish all our members a Very Merry Christmas and Happy New Year!</p>",
        visible: () => {
            const today = new Date();
            const start = new Date(today.getFullYear(), 11, 1);
            const end = new Date(today.getFullYear() + (today.getMonth() === 11 ? 1 : -1), 0, 5);
            return (today >= start && today <= end);
        },
        open: true
    }
]